.submenu-container {
  position: relative;
}

.submenu {
  position: absolute;
  right: 10px;
  top: calc(100% + 10px);
  background-color: #202020;
  border-radius: 10px;
  padding: 15px 0px;
  width: 320px;
  flex-direction: column;
  z-index: 1;
  gap: 15px;
}

.submenu-button {
  width: 290px;
  cursor: pointer;
}