@media only screen and (max-width: 768px) {
  [class*="fc fc-media-screen fc-direction-ltr fc-theme-standard"] {
    margin: unset;
  }
}

@media only screen and (max-width: 1044px) {
  .bar {
    margin: unset !important;
  }
  .columns-title-container {
    margin: unset !important;
  }
  .columns-container {
    margin: unset !important;
  }
  h2, span, p {
    font-size: 14px !important;
  }
  .myteam-title {
    font-size: 34px !important;
    margin: unset !important;
  }
  .status-text {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1192px) {
  .column:nth-child(1) {
    flex: 2 !important;
  }
  .column:nth-child(7) {
    flex: 2 !important;
  }
  .column:nth-child(1) {
    flex: 2 !important;
  }
  .column-title:nth-child(7) {
    flex: 2 !important;
  }
  .absence-icon {
    margin: unset !important;
  }
  .holiday-cancel-button {
    height: unset !important;
  }
}


.myteam-title {
  color: rgba(255, 255, 255, 0.50);
  font-family: Gilroy;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  align-items: center;
  margin-left: 50px;
}

.bar {
  display: flex;
  background-color: #3B3B3B;
  align-items: center;
  margin-top: 50px;
  margin-left: 45px;
  margin-right: 45px;
  height: 120px !important;
  border-radius: 30px 30px 0px 0px;
}

@media only screen and (max-width: 1044px) {
  .search-bar {
    display: none !important;
  }
  .bar {
    border-radius: unset !important;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  width: 350px !important;
  height: 100% !important;
  border: none;
  border-radius: 30px 0px 0px 0px;
  font-size: 16px;
  background-color: #BF0629;
  margin: auto;
}

.title-button-container {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100% !important;
  margin-left: auto;
  margin-right: 20px;
  justify-content: center;
}

.search-bar-input {
  width: 242px;
  height: 32px;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 59px;
  border: 2px solid rgba(255, 255, 255, 0.50);
  background-color: #FFF;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.search-bar-input::placeholder {
  text-align: center;
}

.absence-icon {
  margin-left: 15px;
}

.absence-button {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 24.039px;
  background-color: #BF0629;
}

.myteam-section {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sort-text {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.50);
  text-overflow: ellipsis;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-right: 10px;
  margin-left: 30px;
  padding-left: 10px;
  text-transform: uppercase;
}

.columns-container {
  display: flex;
  margin-left: 45px;
  margin-right: 45px;
  padding: 0;
  background-color: #FFFFFF;
}

.columns-title-container {
  display: flex;
  margin-left: 45px;
  margin-right: 45px;
  text-align: center;
  justify-content: center;
}

.column {
  min-height: 65px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #DDD;
  background-color: #F9F9F9;
}

.column:nth-child(1) {
  flex: 1;
}

.column:nth-child(2) {
  flex: 1;
}

.column:nth-child(3) {
  flex: 1;
}

.column:nth-child(4) {
  flex: 1;
}

.column:nth-child(5) {
  flex: 1;
}

.column:nth-child(6)  {
  flex: 3;
}

.column:nth-child(7)   {
  flex: 1;
}

.column-title h2 {
  overflow-wrap: anywhere;
  color: #BF0629;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: auto;
  display: flex;
}

.column-title {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #DDD;
  padding: 10px 0px;
  background-color: #FFFFFF;
  border: none;
}

.column-title:nth-child(1) {
  flex: 1;
}

.column-title:nth-child(2) {
  flex: 1;
}

.column-title:nth-child(3) {
  flex: 1;
}

.column-title:nth-child(4) {
  flex: 1;
}

.column-title:nth-child(5) {
  flex: 1;
}

.column-title:nth-child(6) {
  flex: 3;
}

.column-title:nth-child(7)  {
  flex: 1;
}

.column-team-title {
  align-items: left;
  margin-left: 150px;
  border: 1px solid #DDD;
  padding: 10px;
  border: none;
  color: #BF0629;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: normal;
}


.status-text {
  flex-grow: 1;
  display: flex;
  height: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  text-align: center;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.holiday-accrual {
  color: #000;
  margin: auto;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

/*Approve/Decline Buttons*/
.status-buttons-container {
  position: relative;
  display: flex;
  margin: auto;
}

.status-buttons {
  display: flex;
  flex-shrink: 0;
  margin-left: 10px;
  border: none;
  flex-direction: column;
}

.approval-btn,
.decline-btn {
  color: #FFFFFF;
  display: flex;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 31px;
  gap: 5px;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: none;
}

.approval-btn:hover,
.decline-btn:hover{
  cursor: pointer;
}

.approval-btn {
  background: #5CA860;
}

.approval-btn:hover {
  background-color: #45894C;
}

.decline-btn {
  background-color: #BF0629;
}

.decline-btn:hover {
  background-color: #941235;
}

/*/////////////////////////////*/

.absence-container {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center !important;
  position: relative;
  margin: 0;
  padding: 0;
}

/*Team member names*/
.user-name {
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin: auto;
  color: #838181;
  font-size: 18px;
  align-items: center;
}


.employee-name {
  color: #000;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.icon-title {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-title h2 {
  font-size: 16px;
}

.user-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #929292;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pattern-overlay {
  position: absolute;
  background: lightgray 0% 0% / 50px 50px repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.comment-icon {
  height: 25px;
  width: 25px;
  margin-left: 10px;
}

.comment-icon {
  position: relative;
  cursor: pointer;
}