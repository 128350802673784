/*Official Redhill Font assigned by Ville & Olga*/
@font-face {
  font-family: 'Gilroy';
  font-weight: 400;
  src: url('./assets/Font/Gilroy-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 800; 
  src: url('./assets/Font/Gilroy-ExtraBold.otf') format('opentype');
}

body {
  font-family: 'Gilroy', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #EBEBEB;
}
