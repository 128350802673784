@media only screen and (min-height: 990px) {
    .absence-popup-overlay {
        align-items: center;
    }
}

/*Popup*/
.absence-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    z-index: 9999;
    overflow-y: scroll;
}

.absence-popup-content {
    background: url('../../assets/Icons/CalendarIcons/Absences/AFbackground.png');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    text-align: center;
    width: 800px;
    height: 991px;
    text-align: center !important;
    border-radius: 20px !important;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.absence-form {
    background-color: #fff;
    color: black;
    width: 520px;
    height: 736px;
    border-radius: 20px;
}

.request-absence-header {
    color: #FFF;
    font-family: Gilroy;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
}

.buttons {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
}

.absence-submit-button,
.absence-cancel-button {
    border-radius: 25px;
    color: #FFFFFF;
    width: 119px;
    height: 45px;
    font-family: Gilroy;
    font-size: 23.078px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin: 10px;
    border: none;
}

.absence-submit-button {
    background-color: #BF0629;
}

.absence-submit-button:hover {
    border-bottom: 6px solid #BF0629;
}

.absence-cancel-button {
    background-color: #202020;
}

.absence-cancel-button:hover {
    border-bottom: 6px solid #202020;
}

.absence-close-button {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    justify-content: flex-end !important;
    margin-left: auto;
    right: 40px;
    top: 45px;
    padding: 0px;
}

/*FORM STYLES*/

/*Datepicker*/
#startDate,
#endDate {
    width: 320px;
    height: 30px;
    font-size: 16px;
    color: black;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #DDD;
    background-color: #DDD;
    outline: none;
}

/*Absence Reason*/
select#reason, select#employee {
    width: 330px;
    height: 30px;
    margin-bottom: 10px;
    background-color: #DDD;
    border: 1px solid #DDD;
    color: black;
    font-family: Gilroy;
    font-size: 18px;
}

/*Textfield*/
input#information {
    width: 320px;
    height: 50px;
    background-color: #DDD;
    margin-bottom: 10px;
    outline: none;
    border: 1px solid #DDD;
    color: black;
    font-family: Gilroy;
    font-size: 18px;
}
