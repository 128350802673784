@media only screen and (max-width: 1480px) {

  .container{
    box-sizing: border-box;
    position: relative;
    display:flex;
    flex-direction:column;
    justify-content:flex-start !important;
    align-items: center;
    width:100vh;
    height:100% !important;
  }

  .left-column, .right-column{
    flex-direction:column;
    justify-content:flex-start;
    align-items: center !important;
    width:100%;
    height:auto;
  }
  
}

@media only screen and (max-width: 768px) {

  .container{
    box-sizing: border-box;
    display:flex;
    position:relative;
    flex-direction:column;
    justify-content:flex-start !important;
    width:100vh;
    height:100vh;
  }

  .left-column, .right-column{
    flex-direction:column;
    justify-content:flex-start;
    width:100%;
    height:auto;
    padding:0;
  }

  .profile-container, .holiday-container, .request-container{
    flex-direction: column;
  }

  .left-profile{
    padding: 0px !important;
    border-radius: 20px 20px 0px 0px !important;
  }
  
  .profile-right-side {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    padding: 25px 75px 25px 75px !important;
    margin:0;
    width:100%;
    color: #FFFFFF;
    border-radius: 0px 0px 20px 20px !important;
    border-top:2px solid #ddd;
    border-left: 0px !important;
    font-size: 24px !important;
  }

  .profile-right-side h3{
    width: clamp(47%, 150px, 100%);
  } 

  .profile-right-side {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:15px;

  }
  
  .vertical-line {
    display: none;
    height:100%;
    width: 2px;
    background-color: #ddd;
  }

  .absence-block {
    padding-right: unset !important;
  }
  p.status {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 1044px) {
  
  p.status {
    margin-top: 29px !important;
    margin-bottom: 29px !important;
  }

}

body {
  margin: 0;
  align-items: center;
}

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 100vh;
  width:100%;
  padding:25px 10px;
  gap:20px;
}

.left-column {
  flex:1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  align-items: flex-end;
  margin:0;
  gap: 20px;
}

.right-column{
  flex:1;
  box-sizing: border-box;
  display: flex;
  height: auto;
  justify-content: flex-start;
  margin:0;
  flex-direction: column;
  gap: 20px;
}

.profile-container,
.holiday-container {
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 20px;
  border-top: 6px solid #BF0629;
  background-color: #FFF;
}

.request-container {
  width: clamp(200px, 100%, 720px);
  height: auto;
  background-color: #FFFFFF;
  flex-shrink: 0;
  border-radius: 20px;
  border-top: 6px solid #BF0629;
  margin: 0px;
}

.request-container-content {
  padding: 20px;
}

.profile-container {
  background-color: #FFFFFF;
  width: clamp(200px, 100%, 720px);
  height: clamp(390px, auto, auto);
  flex-shrink: 0;
  border-radius: 20px;
  border-top: 6px solid #BF0629;
  margin: 0;
  position: relative;
  display: flex;
}

.holiday-container {
  position: relative;
  background-color: #FFFFFF;
  width: clamp(200px, 100%, 720px);
  height: clamp(390px, auto, auto);
  flex-shrink: 0;
  border-radius: 20px;
  border-top: 6px solid #BF0629;
}

hr{
  display: none;
}

.button-container {
  box-sizing: border-box;
  display: grid;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #ddd;
  padding:30px;
}

.holiday-circle-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  padding: 30px;
}

.holiday-circle {
  position: relative;
  display: inline-flex;
  border: 16px solid #ebebeb;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.holiday-circle-workaround {
  position: absolute;
  height: 120px;
}

.circular-progress {
  --size: 120px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 13px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * 3.14159265359 * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: #ebebeb;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  stroke: #7ba47d;
}

@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.holiday-icon,
.inner-holiday-icon {
  width: 152px;
  height: 152px;
}

.inner-holiday-icon {
  position: absolute;
  top: 0;
  left: 0;
}

.holiday-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.profile-info-header {
  display:flex;
  flex-direction: column;
  width:100%;
  color: #929292;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sub-info {
  color: #000;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.name, .name h6 {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.job-title {
  color: #929292;
  text-align: center;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.holiday-title,
.requests-title {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  padding: 30px;
  margin:auto;
  border-bottom: 2px solid #ddd;
}

.request-titles {
  color: #BF0629;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  max-width: fit-content;
  cursor: pointer;
}

.left-profile {
  flex: 1;
  padding: 20px;
  background-color: white;
  text-align: center;
  width:100%;
  padding: 55px;
  border-radius: 20px 0px 0px 20px;
}

.profile-right-side {
  flex: 1;
  padding: 55px;
  margin:0;
  width:100%;
  color: #FFFFFF;
  border-radius: 0px 20px 20px 0px;
  border-left:2px solid #ddd;
}

.absence-container {
  display: flex;
  align-items: flex-start;
}

.absence-block {
  height: 55px;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex: 2;
  padding-right: 30px;
}

.absence-info {
  display: flex;
  align-items: center;
}

.absence-icon {
  height: 32px;
  width: 32px;
  margin-right: 14px;
}

.absence-date {
  color: #FFF;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.absence-type {
  font-style: italic;
  border: 1px solid #000;
  color: #888;
}

/*text in column*/
.status {
  text-align: center;
  white-space: nowrap;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  margin: 28px
}

/*the column*/
.absence-status {
  border: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: center;
}

.pattern {
  position: absolute;
  background: lightgray 0% 0% / 50px 50px repeat;
  top: 0;
  left: 0;
  width: 58%;
  height: 100%;
  opacity: 0.1;
}

.images {
  position: relative;
  top: 0;
  left: 0;
}

.avatar {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  top: 10px;
  left: 20px;
}

.image-frame {
  position: absolute;
  top: 0;
  left: 90px;
}