/*Navbar with stylings according to Figma stylesheet*/
@media only screen and (max-height: 450px) and (max-width: 896px) {

  .navbar{
    box-sizing: border-box;
    height: 100vh !important;
    width: 100% !important;
    flex-direction: row !important;
    flex: 4;
    padding: 30px !important;
  }

  .logo-container{
    flex:1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px 0 0;
    margin: 0 !important;
  }

  .logo-text{
    padding-bottom: 25px;
  }

  .divider{
    display:none !important;
  }

  .nav-items{
    height: 100%;
    flex:3;
    padding: 0 0 0 30px !important;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap:0px !important;
    border-left: 1px solid #ddd;
  }

  .nav-button {
    flex:1;
    width:100% !important;
    border: 0px !important;
  }

  .nav-button.request-button, .nav-button.sign-in {
    flex: none;
    width: 243px !important;
    margin: 30px 0 0 0 !important;
    height: 37px !important;
    padding: 0 30px;
  }

  .nav-button:hover {
    color: #BF0629;
    border-bottom: 10px !important;
    border: 0 !important;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  
  .nav-button.active {
    color: #FFFFFF;
    border-bottom: 0px !important;
    border-left: 0px !important;
    background:#BF0629;
    border: 0 !important;
  }

  .request-button:hover {
    border-bottom: 0px !important;
    border-left: 0px !important;
    color: #FFFFFF;
    background:#D14963;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .sign-out{
    height:50px !important;
    border: 0 !important;;
  }
  
}

@media only screen and  (min-height: 451px) and (max-width: 896px) {

  .logo-container {
    margin: 0;
    padding-right: 25px;
  }

  .navbar {
    display: flex;
    position:relative;
    box-sizing: border-box;
    padding: 20px !important;
    justify-content: stretch !important;
    align-items: center;
    flex-direction: column !important;
    height:100vh;
    z-index: 1000;
  }

  .nav-items {
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    flex:1;
    justify-content: space-around;
    align-items: center;
    width:100%;
    margin: 20px 0;
    gap:0 !important;
  }

  .divider {
    display: flex;
    width:100% !important;
    border-right: 0 !important;
    border-bottom: 1px solid white;
    padding: 0 !important;
    margin: 0 !important;
  }

  .nav-button {
    flex:1;
    width:100% !important;
    border: 0px !important;
  }

  .nav-button.request-button, .nav-button.sign-in {
    flex: none;
    width: 243px !important;
    margin: 30px 0 0 0 !important;
    height: 37px !important;
    padding: 0 30px;
  }

  .nav-button:hover {
    color: #BF0629;
    border-bottom: 10px !important;
    border: 0 !important;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  
  .nav-button.active {
    color: #FFFFFF;
    border-bottom: 0px !important;
    border-left: 0px !important;
    background:#BF0629;
    border: 0 !important;
  }

  .request-button:hover {
    border-bottom: 0px !important;
    border-left: 0px !important;
    color: #FFFFFF;
    background:#D14963;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .sign-out{
    height:50px !important;
    border: 0 !important;;
  }
  
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  background-color: #202020;
  color: #FFFFFF;
  font-weight: bold;
  text-transform: uppercase;
  flex-wrap: wrap;
  padding:0;
}

.nav-items {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: #202020;
  flex-wrap: wrap;
  gap:10px;
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.logo {
  width: 5.25rem;
  height: 5.25rem;
}

.logo-text {
  color: #FFFFFF;
  font-size: 1.875rem !important;
  font-weight: bold;
}

.nav-button.sign-in {
  height: 37px;
  color: #FFF;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  border-radius: 18.75px;
  background: #BF0629;
  margin: auto;
  margin-right: 15px;
  border:0;
  padding:0 30px;
}

.nav-button.sign-in:hover {
  background: #D14963;
  border:0;
}

.nav-button {
  height: 78px;
  text-align: center;
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  border: none;
  outline: none;
  border-bottom: 10px solid #202020;
  background-color: #202020;
  transition: 0.3s;

  color: #FFFFFF;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none; 
}

.request-button, .nav-button.sign-in {
  display: flex;
  height: 50px;
  padding: 10px 30px;
  place-self: center;
  flex-shrink: 0;
  border-radius: 25px;
  border: none;
  background: #BF0629;
  color: #FFF;
  text-align: center;
  font-family: Gilroy;
  font-size: 23.078px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0px !important;
  cursor: pointer;
  height: 37px;
  color: #FFF;
  font-size: 18px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  border-radius: 18.75px;
  background: #BF0629;
  margin: 0 15px 0 15px !important;
}

.request-button:hover {
  border-bottom:0 !important;
  background:#D14963;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.divider {
  border-right: 1px solid #DDD;
  height: 30px; 
  margin: 0 10px; 
}

.logout-icon {
  margin-left: 10px; 
  vertical-align: middle; 
}

.nav-button:hover {
  border-bottom:10px solid #BF0629;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.nav-button.active {
  border-bottom: 10px solid #BF0629;
  background:#BF0629;
}

.sign-in-button-container {
  box-sizing: border-box;
  display: grid;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 0;
  padding:30px;
}