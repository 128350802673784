@media only screen and (max-width: 1044px) {
  .calendar-search-bar {
    display: none !important;
  }
  .fc {
    margin: unset !important;
  }
  .fc-header-toolbar {
    border-radius: unset !important;
  }
  .fc-toolbar-title {
    font-size: 24px !important;
  }
}

.fc {
  background-color: #FFF !important;
  margin-top: 50px;
  margin-left: 45px;
  margin-right: 45px;
  border-radius: 30px 30px 0px 0px;
}

/*Calendar Month*/
#fc-dom h2,
#fc-dom-1,
#fc-dom-2,
#fc-dom-3,
#fc-dom-4,
#fc-dom-5,
.fc-toolbar-title {
  color: rgba(255, 255, 255, 0.50);
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

/*Month Title*/
.fc-header-toolbar {
  background-color: #3a3737;
  border-radius: 30px 30px 0px 0px;
}

.fc-today-button {
  background-color: #BF0629 !important;
  color: rgb(255, 255, 255) !important;
}

.fc-prev-button,
.fc-next-button {
  background-color: #BF0629 !important;
  color: #fff !important;
  margin-right: 20px !important;
}

.fc-day-sat,
.fc-day-sun {
  background-color: #F0F0F0 !important;
  color: #929292;
  align-items: center;
  border: 1px solid #DDD;
}

.fc-day-mon,
.fc-day-tue,
.fc-day-wed,
.fc-day-thu,
.fc-day-fri {
  color: #BF0629;
  align-items: center;
  border: 1px solid #DDD;
}

.fc-day-mon,
.fc-day-tue,
.fc-day-wed,
.fc-day-thu,
.fc-day-fri,
.fc-day-sat,
.fc-day-sun {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.fc-datagrid-cell-main {
  color: #585657;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.fc-day-today {
  background-color: #A7D6E1;
  opacity: 0.4;
  color: #000000;
}

.fc-resource-timeline-divider {
  max-width: 320px !important;
}

.fc-event {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc .fc-bg-event {
  opacity: 1;
}

.fc .fc-datagrid-cell-cushion {
  overflow: visible;
}

.fc .fc-resource-timeline-divider {
  cursor: default;
}

.fc .fc-toolbar {
  height: 120px;
}

.calendar-search-bar {
  display: flex;
  align-items: center;
  width: 350px !important;
  height: 120px !important;
  border: none;
  border-radius: 30px 0px 0px 0px;
  font-size: 16px;
  background-color: #BF0629;
  position: relative;
  top: 170px;
  left: 45px;
  margin-top: -128px;
  flex-direction: column;
}

.calendar-search-bar-input {
  width: 242px;
  height: 32px;
  display: flex;
  flex-direction: column;
  margin: auto auto 0 auto;
  border-radius: 59px;
  border: 2px solid rgba(255, 255, 255, 0.50);
  background-color: #FFF;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px !important;
}

.fc-scrollgrid-section td .fc-scroller-harness .fc-scroller {
  overflow: scroll !important;
}

.absence-Requested {
  background-image: url('../../assets/Icons/CalendarIcons/Absences/requestedPattern.png') !important;
  opacity: 0.1 !important;
  background: lightgray 0% 0% / 50px 50px repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.calendar-export-wrapper {
  margin: 45px;
  display: flex;
}

.calendar-event-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.calendar-footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.calendar-holiday {
  color: #BF0629;
}