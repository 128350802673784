.slack-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slack-integration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3em;
}

.slack-container {
  padding: 3em;
}

.slack-success-title {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.slack-integration-title {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  padding: 30px;
  margin:auto;
  border-bottom: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slack-integration-greyed {
  color: #929292;
  text-align: center;
  padding: 1em;
}