@media only screen and (max-height: 749px){

    .absence-close-button{
        position: fixed;
        top:10px;
        right: 10px;
        width: 50px;
        height: 50px;
    }

    .holiday-popup-content{
        box-sizing: border-box;
        width: 100% !important;
        max-width: 750px !important;
        height:100% !important;
        margin: 10px;
        padding-bottom: 30px;
    }

    .holiday-form .left-side .user-name {
        font-size: 0.6em !important;
    }

    .holiday-form {
        display: flex;
        flex-direction: row !important;
        box-sizing: border-box;
        width:100% !important;
        height:100% !important;
        border-radius: 20px;
    }

    .holiday-form #userName {
        box-sizing: border-box;
        width:100% !important;
        margin:20px;
        font-size: 1em;
    }

    .holiday-form .left-side, .holiday-form .right-side {
        box-sizing: border-box;
        flex:1 !important;
        width: 100% !important;
        height:100%;
        border-radius: 20px !important;
    }

    .user-list{
        display: flex;
        flex-direction: column;
        flex:1;
        height: 160px !important;
        overflow: auto;
        padding: 10px;
    }

    .searchbar {
        flex-direction: row !important;
        border-radius: 20px 0 0 0 !important;
        padding: 20px !important;
    }

    .request-holiday-header, .vacation-number-title{
        font-size: 1em !important;
    }
}

@media only screen and (max-width: 630px){

    .absence-close-button{
        top:10px !important;
        right: 10px !important;
        width: 50px;
        height: 50px;
    }

    .holiday-popup-content{
        box-sizing: border-box;
        width:100% !important;
        margin: 10px;
    }

    .holiday-form .left-side .user-name {
        font-size: 0.6em !important;
    }

    .holiday-form {
        display: flex;
        flex-direction: column !important;
        box-sizing: border-box;
        width:100% !important;
        border-radius: 20px !important;
    }

    .holiday-form #userName {
        box-sizing: border-box;
        width:100% !important;
        margin:20px;
        font-size: 1em;
    }

    .holiday-form .left-side, .holiday-form .right-side {
        box-sizing: border-box;
        flex:1 !important;
        width: 100% !important;
        height:100%;
        border-radius: 20px;
    }

    .user-list{
        display: flex;
        flex-direction: column;
        flex:1;
        height: 160px !important;
        overflow: auto;
        padding: 10px;
    }

    .searchbar {
        flex-direction: row !important;
        border-radius: 20px 20px 0 0 !important;
        padding: 20px !important;
    }

    .request-holiday-header, .vacation-number-title{
        font-size: 1em !important;
    }
}

.holiday-form-container {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height:650px;
}


.holiday-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.holiday-popup-content {
    box-sizing: border-box;
    margin: 10px;
    background: url('../../assets/Icons/MgmtIcons/HFbackground.png');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    text-align: center;
    position: relative;
    width: 630px;
    height: 750px;
    text-align: center !important;
    border-radius: 20px !important;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.request-holiday-header {
    color: #FFF;
    font-family: Gilroy;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 20px;
}

.holiday-form {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height:620px;
    margin:0 30px 30px 30px;
    background-color: #FFF;
    flex-direction: row;
    border-radius: 20px;
    align-items: center;
}


/*left side of the popup*/
.left-side {
    box-sizing: border-box;
    background-color: #F9F9F9;
    flex: 1;
    border-radius: 20px 0px 0px 20px;
    height: clamp(100%, 100%, 620px);
}

.searchbar {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #BF0629;
    width: 100%;
    min-height: 86px;
    padding: 25px 10px 0px 10px;
    border: none;
    border-radius: 20px 0px 0px 0px;
    font-size: 16px;
}

.user-input {
    width: 242px;
    height: 32px;
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 20px;
    border-radius: 59px;
    border: 2px solid rgba(255, 255, 255, 0.50);
    background-color: #FFF;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    padding: 5px;
    text-align: center;
}

.selected-count {
    margin: 10px;
    color: #BF0629;
    font-weight: bold;
}

.user-list {
    display: flex;
    position: relative;
    flex-direction: column;
    flex:1;
    height: 410px;
    overflow: auto;
    padding: 10px;
}

.user-item {
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    display: flex;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.user-name {
    margin-left: 10px;
}

.user-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 10px;
}

/*right side of the popup*/
.right-side {
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.right-side-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.vacation-number-title {
    color: black;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.holiday-number-input {
    height: 40px;
    background-color: #f0f0f0;
    border: none;
    padding: 5px;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
}

.holiday-submit-button,
.holiday-cancel-button {
    border-radius: 25px;
    color: #FFFFFF;
    width: 119px;
    height: 35px;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin: 10px;
    border: none;
}

.holiday-submit-button {
    background-color: #BF0629;
    
}

.holiday-submit-button:hover {
    border-bottom: 3px solid #BF0629;
}

.holiday-cancel-button {
    background-color: #202020;
}

.holiday-cancel-button:hover {
    border-bottom: 3px solid #202020;
}

.holiday-select-button {
    width: 140px !important;
}

.holiday-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/*ManagementView*/

.employee-details {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.employee-avatar {
    margin-right: 20px;
}

.employee-info {
    display: flex;
    flex-direction: column;
}

.employee-info span {
    display: block;
}

.employee-title {
    color: #838181;
    font-size: 18px;
}

.employee-name {
    color: #000;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.holiday-balance {
    margin: auto;
}

.header-container {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100% !important;
    margin-left: auto;
    margin-right: 20px;
    justify-content: space-between;
}

@media only screen and (max-width: 1044px) {
    .header-container {
        flex-direction: column;
        justify-content: center;
    }
}

.header-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}